@import '/home/gitlab-runner/builds/7ZLpFSbW/0/lagom/web_core/src/eva/client/styles/variables.less'; 
.app-login {
  font-weight: 400;
  font-size: x-large;
  font-family: "Roboto";
  color: #3cb17e;
  display: flex;
  align-content: center;
  align-items: center;
  //  text-align: center;
}

.app-text {
  font-weight: 300;
  font-size: 36pt;
  font-family: "Roboto";
  text-align: center;
}

.app-logo {
  fill: #3cb17e;
  height: 72px;
  width: 72px;
}

.app-logo-container {
  padding-bottom: 0;
  padding-top: 0;
}

.app-policy {
  text-decoration: underline;
  color: #666;
  font-family: "Roboto";
  font-size: 9pt;
}

.app-copyright {
  color: #333;
  font-family: "Roboto";
  margin-bottom: 0;
  margin-top: 0;
}

.app-logo-icon-bottom {
  filter: grayscale(100%) brightness(110%) opacity(40%);
  height: 48px;
}

.app-button {
  font-family: "Roboto" !important;
  font-size: small;
}

.app-actions {
  padding-left: 24px;
  padding-right: 24px;
}
